import { render, staticRenderFns } from "./Postulante.vue?vue&type=template&id=51400df5&scoped=true&"
import script from "./Postulante.vue?vue&type=script&lang=js&"
export * from "./Postulante.vue?vue&type=script&lang=js&"
import style0 from "./Postulante.vue?vue&type=style&index=0&id=51400df5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51400df5",
  null
  
)

export default component.exports