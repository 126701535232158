<template>
  <div class="container">
    <div class="section">
      <div class="columns is-multiline">
        <div class="column is-12 title-section">
          <h1>Detalle del postulante</h1>
          <div class="buttons">
              <!-- v-if="user.status === '1'" -->
            <button
              class="button is-primary is-outlined"
              @click="openModalStatus"
            >Actualizar Estatus</button>
            <button
              class="button is-primary"
              @click="showPDF(user.cv)"
            >Descargar CV</button>
          </div>
        </div>
        <div class="column is-12">
          <div class="card">
            <div class="columns is-multiline" v-if="!loading">
              <div class="column is-12">
                <h1 class="title-card">Información del postulante</h1>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Nombre (s)</label>
                  <div class="control">
                    <span>
                      {{user.name}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Primer apellido</label>
                  <div class="control">
                    <span>
                      {{user.last_name}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Segundo Apellido</label>
                  <div class="control">
                    <span>
                      {{user.second_last_name}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Email</label>
                  <div class="control">
                    <span>
                      {{user.email}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Teléfono</label>
                  <div class="control">
                    <span>
                      {{user.phone}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Vacante</label>
                  <div class="control">
                    <span>
                      {{user.vacant_name}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Estatus</label>
                  <div class="control">
                    <span :class="`vacante-${user.status}`">
                      {{user.status === '1' ? 'Postulado' : null}}
                      {{user.status === '3' ? 'Rechazado' : null}}
                      {{user.status === '2' ? 'Aceptado' : null}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-multiline" v-if="loading">
              <div class="column is-12 has-text-centered">
                <div class="lds-dual-ring" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-pdf
      v-if="modal"
      :close="openModalPdf"
      :url="file"
    />
    <update-status
      v-if="modalStatus"
      :close="openModalStatus"
      :postulanteId="user.id"
      :getPostulante="getPostulante"
      :name="`${user.name} ${user.lastName}`"
    />
  </div>
</template>

<script>
import api from '@/libs/api'
const RUTA_FILES = process.env.VUE_APP_RUTA_FILES
export default {
  name: 'Postulante',
  components: {
    ModalPdf: () => import('@/components/postulantes/ModalPDF'),
    UpdateStatus: () => import('@/components/postulantes/UpdateStatus')
  },
  data () {
    return {
      loading: false,
      file: null,
      modal: false,
      modalStatus: false,
      user: {}
    }
  },
  methods: {
    async getPostulante (userId) {
      const response = await api.getPostulante(userId)
      if (response.success) {
        this.user = response.payload
      }
    },
    showPDF (cvFile) {
      this.file = `${RUTA_FILES}${cvFile}`
      this.openModalPdf()
    },
    openModalPdf () {
      this.modal = !this.modal
    },
    openModalStatus () {
      this.modalStatus = !this.modalStatus
    }
  },
  mounted () {
    this.loading = true
    const postulanteId = parseInt(this.$route.params.postulanteId, 10)
    this.getPostulante(postulanteId)
      .then(_ => {
        this.loading = false
      })
  }
}
</script>

<style lang="scss" scoped>
.card {
  padding-bottom: 3rem;
  .label {
    font-size: 14px;
    text-align: left;
    margin-left: 0.5rem;
  }
  .control {
    span {
      text-align: left;
      width: 100%;
      display: block;
      padding-left: 1rem;
      font-size: 13px;
    }
  }
}
.vacante-1 {
  border-radius: 5px;
  background: #ffdd57;
  color: #000000b3;
  display: flex !important;
  width: fit-content !important;
  padding: 0.3rem 1rem !important;
}
.vacante-2 {
  border-radius: 5px;
  background: #48c78e;
  color: #fff;
  display: flex !important;
  width: fit-content !important;
  padding: 0.3rem 1rem !important;
}
.vacante-3 {
  border-radius: 5px;
  background: #f14668;
  color: #fff;
  display: flex !important;
  width: fit-content !important;
  padding: 0.3rem 1rem !important;
}
</style>
